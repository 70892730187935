import React from 'react';
import './AppWrapper.css';
import App from '../App';
import MainLoader from '../components/loader/MainLoader';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { AxiosProvider } from '../providers/AxiosProvider';
import ScrollToTop from './ScrollToTop';

function AppWrapper() {
  const { authenticated, loading, token, sessionId } = useAuth();
  const queryClient = new QueryClient();

  return (
    <React.Fragment>
      {!authenticated && loading && (
        <div className="App">
          <header className="App-header">
            <MainLoader />
            <br />
            <Typography sx={{ color: '#000000' }}>Loading User Access..</Typography>
          </header>
        </div>
      )}
      {!authenticated && !loading && (
        <div className="App">
          <header className="App-header">
            <Typography sx={{ color: '#000000' }}>Error could not reach Auth Service</Typography>
          </header>
        </div>
      )}
      {authenticated && !loading && token && sessionId && (
        <AxiosProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </BrowserRouter>
          </QueryClientProvider>
        </AxiosProvider>
      )}
    </React.Fragment>
  );
}

export default AppWrapper;
