import { create } from 'zustand';

type TErrorState = {
  show: boolean;
};

export type TErrorStoreState = {
  error: TErrorState;
  showError: () => void;
};

const defaultState = {
  show: false,
};

const usePopupStore = create<TErrorStoreState>((set) => ({
  error: defaultState,
  showError: () =>
    set(() => ({
      error: { show: true },
    })),
}));

export default usePopupStore;
