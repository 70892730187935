import { useTranslation } from 'react-i18next';
import WhatsAppIcon from '../../../icons/whatsapp';

const MobileWhatsAppBanner = () => {
  const { t } = useTranslation('header');
  return (
    <a
      className="flex h-[47px] w-full items-center justify-center bg-highlight-3 text-[10px] text-white visited:text-white"
      href="https://api.whatsapp.com/send/?phone=5491148222282&text=This+is+a+test&type=phone_number&app_absent=0"
      target="_blank"
      rel="noopener noreferrer"
    >
      <WhatsAppIcon className="mr-[0.2rem] h-[23px] w-[23px]" />
      <span className="mr-[0.15rem]">{t('whatsapp.have-doubts')}</span>
      <span className="font-bold">{t('whatsapp.whatsapp-consult')}</span>
    </a>
  );
};

export default MobileWhatsAppBanner;
