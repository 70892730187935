import { create } from 'zustand';

type TLoaderState = {
  show: boolean;
};

export type TLoaderStoreState = {
  loader: TLoaderState;
  showLoader: () => void;
  hideLoader: () => void;
};

const defaultState = {
  show: false,
};

const useLoaderStore = create<TLoaderStoreState>((set) => ({
  loader: defaultState,
  showLoader: () =>
    set(() => ({
      loader: { show: true },
    })),
  hideLoader: () =>
    set(() => ({
      loader: { show: false },
    })),
}));

export default useLoaderStore;
