const VouchersIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 500 500"
      version="1.1"
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      className={className}
    >
      <g transform="translate(0 -3)">
        <path
          fill="#fcc015"
          d="M380.96 383.96H119.04c-24.1-.03-43.62-19.56-43.65-43.65V165.7c.03-24.1 19.56-43.62 43.65-43.65h261.91c24.1.03 43.62 19.56 43.65 43.65v174.6c-.02 24.1-19.55 43.63-43.64 43.66zM119.04 151.15c-8.03.01-14.54 6.52-14.55 14.55v174.6c.01 8.03 6.52 14.54 14.55 14.55h261.91c8.03 0 14.55-6.52 14.55-14.55V165.7c-.01-8.03-6.52-14.54-14.55-14.55H119.04z"
        ></path>
        <g transform="translate(7.821 9.026)">
          <path
            d="M168.42 231.2h142.2v29.09h-142.2z"
            fill="#1434cb"
            transform="rotate(-51.007 239.537 245.754)"
          ></path>
          <path
            d="M349.76 277.63c-2.12-19.96-20-34.44-39.97-32.35-19.98 2.1-34.47 20-32.36 39.98v.02c1.99 18.47 17.57 32.49 36.15 32.53 1.27 0 2.55-.06 3.81-.19 19.98-2.12 34.46-20.01 32.37-39.99zm-30.52 8.38a7.222 7.222 0 01-4.87 2.69 7.473 7.473 0 01-8.06-6.47c-.21-1.93.37-3.87 1.6-5.37a7.2 7.2 0 014.86-2.69c3.99-.36 7.55 2.5 8.06 6.47.21 1.94-.37 3.87-1.59 5.37z"
            fill="#1434cb"
          ></path>
          <path
            d="M210.17 199.55c-2.1-19.98-20-34.48-39.98-32.39-19.98 2.1-34.48 20-32.39 39.98 1.94 18.52 17.56 32.58 36.18 32.58 1.27 0 2.55-.06 3.81-.19 19.97-2.13 34.44-20.01 32.38-39.98zm-35.42 11.05c-3.95.46-7.53-2.38-7.99-6.33-.01-.05-.01-.1-.02-.14-.41-4 2.48-7.57 6.47-8.01.26-.01.53-.01.79 0a7.28 7.28 0 017.3 7.26 7.293 7.293 0 01-6.56 7.27v-.05z"
            fill="#1434cb"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default VouchersIcon;
