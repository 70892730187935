export const applicationSettings = {
  clientId: process.env.REACT_APP_VISA_SSO_CLIENT_ID,
  domain: process.env.REACT_APP_VISA_SSO_DOMAIN,
  audience: process.env.REACT_APP_VISA_SSO_AUDIENCE,
  scope: 'profile',
  backEndUrl: process.env.REACT_APP_BACKEND_URL,
  visaLoginPage: process.env.REACT_APP_VISA_LOGIN_PAGE,
  visaLogoutPage: process.env.REACT_APP_VISA_LOGOUT_PAGE,
  visaOnboardingPage: process.env.REACT_APP_VISA_ONBOARDING_PAGE,
  myProfilePage: process.env.REACT_APP_VISA_MYPROFILE_PAGE,
  visaAppPageUrl: process.env.REACT_APP_VISA_APPLICATION_PAGE_URL,
};
