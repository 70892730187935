import { ReactNode } from 'react';
import { create } from 'zustand';

type TPopupUpdateState = {
  title?: string | ReactNode;
  description: string | ReactNode;
  confirmText?: string | ReactNode;
  dismissText?: string | ReactNode;
  onConfirm?: () => void;
};

interface TPopupState extends TPopupUpdateState {
  show: boolean;
}

export type TPopupStoreState = {
  popup: TPopupState;
  showPopup: (arg1: TPopupUpdateState) => void;
  removePopup: () => void;
};

const defaultState = {
  show: false,
  title: '',
  description: '',
  confirmText: '',
  dismissText: '',
  onConfirm: () => false,
};

const usePopupStore = create<TPopupStoreState>((set) => ({
  popup: defaultState,
  showPopup: ({ title, description, confirmText, dismissText, onConfirm }: TPopupUpdateState) =>
    set(() => ({
      popup: { show: true, title, description, confirmText, dismissText, onConfirm },
    })),
  removePopup: () => set({ popup: defaultState }),
}));

export default usePopupStore;
