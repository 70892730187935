const ProfileIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      className={className}
    >
      <path
        fill="#1434cb"
        d="M394.197 242.54l-20-30v-28.33c-.08-73.6-59.73-133.25-133.33-133.33-73.23.97-132.36 60.1-133.33 133.33.02 22.83 7.11 45.09 20.28 63.73a62.562 62.562 0 0113.05 36.27c-.5 8.42-2.64 16.67-6.32 24.27l29.83 14.92a97.664 97.664 0 009.82-39.18 94.525 94.525 0 00-18.02-53.78 77.87 77.87 0 01-15.32-46.22c.73-54.92 45.07-99.27 100-100 55.2.06 99.94 44.79 100 100v38.33l25.65 38.48-22.62 11.32-14.98 45.2h-21.38c-20.15-3.54-40.11 7.15-48.33 25.88l-10.97 21.93 29.82 15 10.98-21.95c3.8-7.53 3.8-7.53 18.5-7.53h21.38c14.38.01 27.14-9.2 31.67-22.85l10.6-32 10.28-5.13c16.47-8.23 23.15-28.25 14.92-44.72-.62-1.25-1.32-2.46-2.1-3.61l-.08-.03z"
      ></path>
      <path fill="#fcc015" d="M100.083 365.802l14.906-29.811 166.658 83.329-14.905 29.811z"></path>
    </svg>
  );
};

export default ProfileIcon;
