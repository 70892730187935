/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-properties */
import axios, { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { applicationSettings } from '../config';

const axiosInstance = axios.create();

// eslint-disable-next-line react/prop-types
const AxiosProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const sessionId = localStorage.getItem('session_id');

    const interceptorResponse = axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error: AxiosError) {
        if (error.response?.status === 401) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('session_id');
          window.location.href = applicationSettings.visaLoginPage as string;
        }
        return Promise.reject(error);
      }
    );

    let interceptorRequest: any = null;

    if (token && sessionId) {
      interceptorRequest = axiosInstance.interceptors.request.use(async (config) => {
        (config.headers as unknown as any).Authorization = `Bearer ${token}`;
        (config.headers as unknown as any).sessionId = sessionId;
        return config;
      });
    }

    return () => {
      if (token && sessionId) {
        axiosInstance.interceptors.request.eject(interceptorRequest);
        axiosInstance.interceptors.response.eject(interceptorResponse);
      }
    };
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default axiosInstance;
export { AxiosProvider };
