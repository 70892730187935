import { create } from 'zustand';

type TUserState = {
  id: string | null;
  programId: number | null;
  catalogId: number | null;
  username: string;
  points: number;
  pointsToBeat: number;
};

export type TUserStoreState = {
  user: TUserState;
  updatePoints: (arg1: number) => void;
  saveUser: (user: TUserState) => void;
};

const defaultState: TUserState = {
  id: null,
  programId: null,
  catalogId: null,
  username: '',
  points: 0,
  pointsToBeat: 0,
};

const useUserStore = create<TUserStoreState>((set) => ({
  user: defaultState,
  updatePoints: (pointsToRest: number) =>
    set((state: { user: TUserState }) => ({
      user: { ...state.user, points: pointsToRest },
    })),
  saveUser: (user: TUserState) =>
    set((state: { user: TUserState }) => ({
      user: { ...state.user, ...user },
    })),
}));

export default useUserStore;
