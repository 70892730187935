import WhatsAppIcon from '../../../icons/whatsapp';

const DesktopWhatsAppBanner = () => {
  return (
    <div className="fixed bottom-20 flex w-[inherit] max-w-[inherit] justify-end pr-20">
      <a
        href="https://api.whatsapp.com/send/?phone=5491148222282&text=This+is+a+test&type=phone_number&app_absent=0"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon className="h-[72px] w-[72px]" />
      </a>
    </div>
  );
};

export default DesktopWhatsAppBanner;
