const SupportIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      className={className}
    >
      <path
        fill="#fcc015"
        d="M250.01 403.75c-84.5 0-153-68.5-153-153s68.5-153 153-153 153 68.5 153 153c-.1 84.46-68.55 152.9-153 153zm0-280.51c-70.39 0-127.46 57.07-127.46 127.46s57.07 127.46 127.46 127.46 127.46-57.07 127.46-127.46c-.11-70.35-57.11-127.34-127.46-127.45v-.01z"
      ></path>
      <path
        fill="#1434cb"
        d="M289.05 341.59h-78.1v-25.5h31.88v-52.56h-26.3v-25.54h51.81v78.1h20.72v25.5zm-39.06-122.73c-16.28-.01-29.48-13.21-29.47-29.49.01-16.28 13.21-29.48 29.49-29.47 16.28.01 29.47 13.2 29.47 29.48-.01 16.28-13.21 29.47-29.49 29.48zm0-33.47c-2.2.01-3.97 1.79-3.97 3.99s1.79 3.97 3.99 3.97a3.986 3.986 0 003.97-3.97 3.993 3.993 0 00-3.99-3.98v-.01z"
      ></path>
    </svg>
  );
};

export default SupportIcon;
