import React, { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from './components/layout';
import useUserStore from './store/user';
import useErrorStore, { TErrorStoreState } from './store/error';
import useLoaderStore, { TLoaderStoreState } from './store/loader';
import { TUserStoreState } from './store/user';
import useFetchUserMe from './hooks/useFetchUserMe';

const Home = React.lazy(() => import('./routes/home'));
const Catalog = React.lazy(() => import('./routes/catalog'));
const MyPoints = React.lazy(() => import('./routes/my-points'));
const MyVouchers = React.lazy(() => import('./routes/my-vouchers'));
const FrequentQuestions = React.lazy(() => import('./routes/frequent-questions'));
const BenefitDetail = React.lazy(() => import('./routes/detailBenefit'));
const Support = React.lazy(() => import('./routes/support'));
const GenericError = React.lazy(() => import('./components/shared/error'));
const Logout = React.lazy(() => import('./routes/logout'));
const ExternalLogout = React.lazy(() => import('./routes/externalLogout'));

type TRoute = { Component: React.ElementType; props: { path: string; index?: boolean } };

const App = () => {
  const { t, ready: i18nReady } = useTranslation('routes');
  const { saveUser, user: currentUser } = useUserStore((state: TUserStoreState) => state);
  const { isLoading, data: user } = useFetchUserMe();
  const { error } = useErrorStore((state: TErrorStoreState) => state);
  const { showLoader } = useLoaderStore((state: TLoaderStoreState) => state);

  useEffect(() => {
    showLoader();
  }, []);

  useEffect(() => {
    !isLoading && currentUser.id != user.id && saveUser(user);
  }, [isLoading]);

  if (!i18nReady) return null;

  const routes: TRoute[] = [
    {
      Component: Home,
      props: { path: '/', index: true },
    },
    {
      Component: MyPoints,
      props: { path: t('my-points') || '/my-points' },
    },
    {
      Component: MyVouchers,
      props: { path: t('my-vouchers') || '/my-vouchers' },
    },
    {
      Component: Catalog,
      props: { path: t('/benefits-catalog') || '/benefits-catalog' },
    },
    {
      Component: BenefitDetail,
      props: { path: t('benefit', { benefitId: ':benefitId' }) || '/benefit/:benefitId' },
    },
    {
      Component: FrequentQuestions,
      props: { path: t('frequent-questions') || '/frequent-questions' },
    },
    {
      Component: Support,
      props: { path: t('support') || '/support' },
    },
    {
      Component: Logout,
      props: { path: t('logout') || '/logout' },
    },
    {
      Component: ExternalLogout,
      props: { path: '/externallogout' },
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {routes.map(({ Component, props }: TRoute) => (
          <Route
            key={props.path}
            element={
              <Suspense fallback={<div />}>
                {!error.show ? <Component /> : <GenericError />}
              </Suspense>
            }
            {...props}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default App;
