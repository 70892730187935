const QuestionIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      className={className}
    >
      <path
        fill="#1434cb"
        d="M259.4 417.52c-90.8 0-164.4-73.6-164.4-164.4s73.6-164.4 164.4-164.4 164.4 73.6 164.4 164.4c-.1 90.75-73.64 164.3-164.4 164.4zm0-301.41c-75.66 0-137 61.34-137 137s61.34 137 137 137 137-61.34 137-137c-.08-75.62-61.37-136.91-137-137z"
      ></path>
      <path
        fill="#fcc015"
        d="M266.25 349.02h-27.4v-27.4h27.4v27.4zm0-54.81h-27.4v-41.1h13.7c18.6 0 41.1-15.28 41.1-34.25 0-18.92-15.33-34.25-34.25-34.25s-34.25 15.33-34.25 34.25h-27.4c0-34.05 27.6-61.65 61.65-61.65s61.65 27.6 61.65 61.65c0 30.35-26.74 54.09-54.8 60.16v15.19z"
      ></path>
    </svg>
  );
};

export default QuestionIcon;
