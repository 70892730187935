import { useTranslation } from 'react-i18next';
import { checkUserPoints } from '../../utils/checkUserPoints';
import useBenefitStore, { TBenefitStoreState } from '../../store/benefit';
import useVoucherStore, { TVoucherStoreState } from '../../store/voucher';
import styles from './bannerStyles.module.css';

type BannerPointsProps = {
  myPoints: number;
  firstTextBackground: string;
  secondTextBackground: string;
};

const BannerPoints = ({
  myPoints,
  firstTextBackground,
  secondTextBackground,
}: BannerPointsProps) => {
  const { t } = useTranslation('home');

  const benefitSelected = useBenefitStore((state: TBenefitStoreState) => state.benefit);
  const currentVoucher = useVoucherStore((state: TVoucherStoreState) => state.voucher);

  const i18nCongratulationsKey = secondTextBackground === 'Felicitaciones';

  return (
    <div
      className={`flex h-[127px] w-full items-center bg-brand-primary ${
        i18nCongratulationsKey ? 'pl-0' : 'pl-96'
      }`}
    >
      <div className="flex flex-1 flex-row items-center">
        {!currentVoucher.id && (
          <div className="text-[80px] font-semibold leading-[96px] tracking-[-1.6px] text-white">
            {myPoints}
          </div>
        )}
        {currentVoucher.id ? (
          <div className="flex w-full flex-col items-center">
            <span className="text-[50px] font-semibold leading-[45px] text-white">
              {t('¡Felicitaciones!')}
            </span>
            <span className="mt-1 text-[34px] font-normal leading-[45px] text-white">
              {t('Ya ténes tu voucher')}
            </span>
          </div>
        ) : checkUserPoints(myPoints, benefitSelected?.points || 0) ? (
          <div className="ml-3 flex w-[100px]">
            <span className="text-[20px] font-light leading-5 text-white">
              {t('Puntos Disponibles')}
            </span>
          </div>
        ) : (
          <div className="ml-24 flex w-[250px]">
            <span className="text-[20px] font-medium leading-5 text-white">
              {t('No tienes suficientes puntos para este voucher')}
            </span>
          </div>
        )}
      </div>
      {!i18nCongratulationsKey && (
        <div className="flex h-full w-full flex-col items-end  text-[80px] font-semibold leading-[22px] text-white opacity-10">
          {firstTextBackground && (
            <div className="mr-36 pt-2 pb-10">
              <span>{t(firstTextBackground)}</span>
            </div>
          )}
          <div
            className={`flex flex-1 items-start overflow-hidden text-clip whitespace-nowrap ${
              firstTextBackground ? 'pt-4' : 'pt-20'
            }`}
          >
            <span>{t(secondTextBackground)}</span>
          </div>
        </div>
      )}
      {i18nCongratulationsKey && (
        <div
          data-customattr={t('Felicitaciones')}
          className={`bgText relative text-clip whitespace-nowrap text-[90px] font-semibold leading-[45px] text-white opacity-10 ${styles.bgtext}`}
        />
      )}
    </div>
  );
};

export default BannerPoints;
