import React, { useState } from 'react';
import FooterDesktop from './footerDesktop';
import FooterMobile from './footerMobile';

const availableValues = [
  {
    value: 'Argentina',
  },
];

const Footer = () => {
  const [value, setValue] = useState<string>(availableValues[0].value);

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };
  return (
    <>
      <FooterDesktop value={value} onChange={handleOnChange} availableValues={availableValues} />
      <FooterMobile value={value} onChange={handleOnChange} availableValues={availableValues} />
    </>
  );
};

export default Footer;
