const CloseIcon = ({ className, strokeWidth }: { className?: string; strokeWidth?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#FFFFFF"
      className={className}
    >
      <path
        stroke={strokeWidth && '#FFFFFF'}
        strokeWidth={strokeWidth}
        d="M19.853 19.147L12.707 12l7.146-7.147a.5.5 0 00-.707-.707L12 11.293 4.853 4.147a.5.5 0 00-.707.707L11.293 12l-7.147 7.146a.5.5 0 10.707.707L12 12.707l7.146 7.147a.498.498 0 00.707 0 .5.5 0 000-.707z"
      ></path>
    </svg>
  );
};

export default CloseIcon;
