const MenuIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#FFFFFF"
      className={className}
    >
      <g data-name="Layer 2">
        <g data-name="menu">
          <rect width="18" height="1" x="3" y="12" rx="0.95" ry="0.95"></rect>
          <rect width="18" height="1" x="3" y="18" rx="0.95" ry="0.95"></rect>
          <rect width="18" height="1" x="3" y="6" rx="0.95" ry="0.95"></rect>
        </g>
      </g>
    </svg>
  );
};

export default MenuIcon;
