import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../hooks/useMediaQuery';
import HomeIcon from '../../../icons/home';
import CatalogIcon from '../../../icons/catalog';
import ProfileIcon from '../../../icons/profile';
import PointsIcon from '../../../icons/points';
import VouchersIcon from '../../../icons/vouchers';
import QuestionIcon from '../../../icons/question';
import SupportIcon from '../../../icons/support';
import BackIcon, { MobileHeaderLogoutIcon, DesktopHeaderBackIcon } from '../../../icons/back';
import { applicationSettings } from '../../../config';

const useHeaderLinks = () => {
  const { t } = useTranslation('header');
  const { t: tr } = useTranslation('routes');
  const isMobile = useMediaQuery('(max-width: 1279px)');

  const links = [
    { label: t('nav.start'), Icon: HomeIcon, path: '/' },
    {
      label: t('nav.catalog'),
      Icon: CatalogIcon,
      path: tr('/benefits-catalog') || '/benefits-catalog',
    },
    {
      label: t('nav.my-profile'),
      Icon: ProfileIcon,
      path: `${applicationSettings.myProfilePage}`,
      isExternal: true,
    },
    { label: t('nav.my-points'), Icon: PointsIcon, path: tr('my-points') || '/my-points' },
    { label: t('nav.my-vouchers'), Icon: VouchersIcon, path: tr('my-vouchers') || '/my-vouchers' },
    {
      label: t('nav.frequent-questions'),
      Icon: QuestionIcon,
      path: tr('frequent-questions') || '/frequent-questions',
    },
    {
      label: t('nav.support'),
      Icon: SupportIcon,
      path: tr('support') || '/support',
    },
    {
      label: `${t('nav.back-to')} visa.com`,
      Icon: isMobile ? BackIcon : DesktopHeaderBackIcon,
      path: 'https://visa.com.ar',
      isExternal: true,
    },
    { label: t('nav.logout'), Icon: MobileHeaderLogoutIcon, path: '/logout' },
  ];

  return links;
};

export default useHeaderLinks;
