import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import useHeaderLinks from './useHeaderLinks';
import VisaIcon from '../../../icons/visa';
import BurgerIcon from '../../../icons/burger';
import CloseIcon from '../../../icons/close';
import styles from './mobile.module.css';
import { applicationSettings } from '../../../config';

const MobileHeader = () => {
  const [menuState, setMenuState] = useState<'show' | 'fading-out' | 'hidden'>('hidden');
  const links = useHeaderLinks();
  const { pathname } = useLocation();

  const MenuIcon = menuState !== 'hidden' ? CloseIcon : BurgerIcon;

  const handleMenuClick = () => {
    switch (menuState) {
      case 'show':
        setMenuState('fading-out');
        break;
      case 'fading-out':
        setMenuState('hidden');
        break;
      case 'hidden':
        setMenuState('show');
    }
  };

  const mobileLinks = links.map(({ label, Icon, path, isExternal }, index) => {
    const setItemCss = () => {
      if (path === pathname) return 'font-bold text-black';
      if (index !== links.length - 1) return 'text-black';
      return '';
    };
    const props = {
      href: path,
      to: path || '/',
      className: setItemCss(),
      onClick: handleMenuClick,
    };

    const externalLink = <a {...props}>{label}</a>;
    const internalLink = <Link {...props}>{label}</Link>;
    return (
      <li
        key={label}
        className="flex items-center gap-[20px]  border-b-[1px] border-dotted border-b-[gray] p-[10px] last:border-0"
      >
        <Icon className="w-[30px]" />
        {isExternal ? externalLink : internalLink}
      </li>
    );
  });

  return (
    <header className="align-center relative z-50 flex h-[70px] w-full justify-between border-b-[1px] border-solid border-b-[#dedede] bg-brand-primary px-[20px]">
      <a href={applicationSettings.visaAppPageUrl} className="flex items-center">
        <VisaIcon className="w-[62px]" color="#FFFFFF" />
      </a>
      <button onClick={handleMenuClick}>
        <MenuIcon className="w-[30px]" />
      </button>
      {menuState !== 'hidden' && (
        <nav
          className={`absolute top-[70px] left-0 z-10 w-full bg-white px-[40px] ${
            menuState === 'fading-out' ? styles.fadeOut : styles.fadeIn
          }`}
          onTransitionEnd={handleMenuClick}
        >
          <ul>{mobileLinks}</ul>
        </nav>
      )}
    </header>
  );
};

export default MobileHeader;
