import { create } from 'zustand';
import { TBenefit } from '../components/home/types';

export type TBenefitStoreState = {
  benefit: TBenefit;
  setSelectedBenefit: (benefit: TBenefit) => void;
  reset: () => void;
};

const defaultState: TBenefit = {
  id: '',
  image: '',
  thumbnail: '',
  print: '',
  points: 0,
  discount: {
    value: '',
    type: '',
  },
  company: '',
  locations: [],
  title: '',
  description: '',
  termsAndConditions: '',
  category: '',
  featured: false,
  featuredTitle: '',
  featuredSubtitle: '',
};

const useBenefitStore = create<TBenefitStoreState>((set) => ({
  benefit: defaultState,
  setSelectedBenefit: (benefit: TBenefit) =>
    set(() => ({
      benefit: { ...benefit },
    })),
  reset: () => {
    set(() => ({ benefit: defaultState }));
  },
}));

export default useBenefitStore;
