const BackIcon = ({
  className,
  arrowColor,
  doorColor,
}: {
  className?: string;
  arrowColor?: string;
  doorColor?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      className={className}
    >
      <path
        fill={arrowColor || '#fcc015'}
        d="M340.65 177.48l-25.55 25.57 28.56 28.83H195.49v36.26h148.17L315.1 296.7l25.55 25.8 52.06-52.06L413.15 250z"
      ></path>
      <path
        fill={doorColor || '#1434cb'}
        d="M123.11 123.1h126.9V86.84h-126.9c-20 .06-36.2 16.26-36.26 36.26v253.8c.06 20 16.26 36.2 36.26 36.26h126.9V376.9h-126.9V123.1z"
      ></path>
    </svg>
  );
};

export const DesktopHeaderBackIcon = ({ className }: { className?: string }) => (
  <BackIcon className={className} arrowColor="#5C5C5C" doorColor="#5C5C5C" />
);

export const MobileHeaderLogoutIcon = ({ className }: { className?: string }) => (
  <BackIcon className={className} arrowColor="#1434cb" />
);

export default BackIcon;
