/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

type CustomSelectProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  availableValues: any;
  className: string;
};

const CustomSelect = ({ value, onChange, availableValues, className }: CustomSelectProps) => {
  return (
    <select value={value} onChange={onChange} className={className}>
      {availableValues.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;
