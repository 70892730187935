import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../providers/AxiosProvider';

const useFetchUserMe = (force = false) => {
  return useQuery({
    queryKey: ['getUserMe'],
    queryFn: () =>
      axiosInstance
        .get(`${process.env.REACT_APP_BACKEND_URL}/user${force ? '?force=true' : ''}`)
        .then((response) => response.data),
  });
};

export default useFetchUserMe;
