import usePopupStore, { TPopupStoreState } from '../../store/popup';
import CloseIcon from '../../icons/close';
import { ReactNode } from 'react';

const handleDescription = (descriptionProp: string | ReactNode) => {
  const descriptionIsString = typeof descriptionProp === 'string';
  if (!descriptionIsString) return descriptionProp;

  const defaultForString = (
    <p className="mb-[32px] text-center text-sm text-[#5C5C5C] desktop:text-[20px]">
      {descriptionProp}
    </p>
  );

  return defaultForString;
};

const Popup = () => {
  const { popup: state, removePopup } = usePopupStore((state: TPopupStoreState) => state);
  const { show, title, confirmText, dismissText, onConfirm } = state;

  if (!show) return null;

  const description = handleDescription(state.description);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    removePopup();
  };

  const confirm = confirmText && (
    <button className="btn btn-primary desktop:w-[346px]" onClick={handleConfirm}>
      {confirmText}
    </button>
  );

  const dismiss = dismissText && (
    <button className="btn mt-2 text-[#1434CB] desktop:w-[346px]" onClick={removePopup}>
      {dismissText}
    </button>
  );

  return (
    <>
      <div
        className="fixed top-0 z-50  h-full w-full bg-[#CECECE] opacity-80"
        onClick={removePopup}
      />
      <div className="fixed top-[80px] z-[51] flex w-full justify-center">
        <div className="shadow-[0px 3px 20px #0000001A] w-full px-[30px] desktop:top-[15vh] desktop:w-[40vw]">
          <div role="dialog" className="rounded-lg bg-white px-[20px] pb-[30px] pt-[12px]">
            <div className="flex justify-end">
              <button
                onClick={removePopup}
                className="flex h-[36px] w-[36px] items-center justify-center rounded-[50%] bg-[#1434CB]"
              >
                <CloseIcon className="w-[20px]" strokeWidth="1.5" />
              </button>
            </div>
            <section className="flex flex-col items-center desktop:px-[72px]">
              <h2 className="mb-5 text-center text-[22px] desktop:text-[25px]">{title}</h2>
              {description}
              {confirm}
              {dismiss}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
