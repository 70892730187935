import { Suspense, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RoundedDiv from './roundedDiv';
import SevenDaysBtn from './seven-days-btn';
import { checkUserPoints } from '../../utils/checkUserPoints';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

type PuntosMobileProps = {
  myPoints: number;
  pointsToBeat: number;
  open: boolean;
  setOpen?: (open: boolean) => void;
  benefitRequiredPoints?: number;
};

const PointsMobile = ({
  myPoints,
  pointsToBeat,
  open,
  setOpen,
  benefitRequiredPoints,
}: PuntosMobileProps) => {
  const { t } = useTranslation('home');
  const { t: tr } = useTranslation('routes');
  const alwaysOpen = open && !setOpen;
  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpen && setOpen(false));
  const navigate = useNavigate();

  return (
    <>
      <div
        ref={ref}
        onClick={() => setOpen && setOpen(false)}
        className={`${
          !open
            ? 'z-50 bg-highlight-3'
            : `${alwaysOpen ? 'static' : 'absolute'} z-40  w-full bg-transparent`
        }`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`relative z-0 w-full rounded-br-xl rounded-bl-xl  ${
            open ? 'h-[332px] bg-[#F7F7F7] md:h-[397px]' : 'h-[100.5px] bg-brand-primary'
          } }`}
        >
          <div
            className={`relative flex w-full flex-1 items-end justify-center ${
              open ? 'h-[169.5px] md:h-[204.5px]' : 'h-full'
            } `}
          >
            <RoundedDiv className={`absolute z-[-1] ${open ? 'flex h-full' : 'hidden h-full'} `} />
            <div className={`h-full flex-col items-center pt-[26.5px] ${open ? 'flex' : 'hidden'}`}>
              <span className="text-2xl font-semibold text-white md:font-medium">
                {t('Mis puntos')}
              </span>
              <span className="text-[70px] font-semibold leading-[84px] text-white md:text-[100px]">
                {myPoints}
              </span>
            </div>
            <div
              className={`absolute top-0 hidden flex-col text-[100px] font-semibold text-white opacity-10 ${
                open ? 'md:block' : 'md:hidden'
              }`}
            >
              <div className="table-caption indent-[-70px] capitalize leading-[5.25rem] tracking-wide">
                {t('Mis puntos')}
              </div>
            </div>
            <div
              className={`ml-6 mb-3 h-full w-full flex-row items-center  ${
                open ? 'hidden' : 'flex'
              }`}
            >
              {checkUserPoints(myPoints, benefitRequiredPoints || 0) ? (
                <>
                  <span className="text-[50px] font-semibold leading-[60px] tracking-[-0.2px] text-white">
                    {myPoints}
                  </span>

                  <div className="ml-3 flex w-[100px]">
                    <span className="text-[20px] font-light leading-5 text-white">
                      {t('Puntos Disponibles')}
                    </span>
                  </div>
                </>
              ) : (
                <div className="ml-3 flex w-[250px]">
                  <span className="text-[20px] font-semibold leading-6 text-white">
                    {t('No tienes suficientes puntos para este voucher')}
                  </span>
                </div>
              )}
            </div>
            {setOpen && (
              <div
                className={`absolute flex h-6 w-6  justify-center rounded-xl ${
                  open
                    ? 'bottom-[-172px] bg-[#F7F7F7] md:bottom-[-202px]'
                    : 'bottom-[-12px]  bg-brand-primary'
                }`}
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="translate-y-1.5 rotate-180 text-sm font-bold text-brand-primary"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="translate-y-1.5 text-sm font-bold text-white"
                  />
                )}
              </div>
            )}
          </div>

          <div
            className={`z-10 mt-6  w-full flex-row items-center justify-center ${
              open ? 'flex' : 'hidden'
            }`}
          >
            <div>
              <span className=" text-[40px] font-semibold leading-[48px] text-black">
                {pointsToBeat}
              </span>
            </div>
            <div className="mx-4 h-[47px] w-px bg-[#707070] opacity-[.27]"></div>
            <div className="flex h-full flex-col md:flex-row">
              <span className="text-lg font-medium leading-4 text-[var(--neutral-color-gray-ultradark)] md:leading-relaxed">
                {t('Puntos por vencer')}&nbsp;
              </span>
              <Suspense fallback={<div />}>
                <SevenDaysBtn />
              </Suspense>
            </div>
          </div>
          <div className="mx-8 flex items-center justify-center">
            <button
              className={`btn btn-primary mt-[23px] max-w-[354px]`}
              style={{ display: open ? 'inline-flex' : 'none' }}
              onClick={() => {
                setOpen && setOpen(!open);
                navigate(tr('my-vouchers') || '/my-vouchers');
              }}
            >
              {t('Ver mis vouchers')}
              <FontAwesomeIcon
                icon={faChevronRight}
                className="translate-y-0.5 p-2 text-sm font-semibold text-brand-secondary"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsMobile;
