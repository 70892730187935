/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomDropDown from './customDropDown';
import CustomSelect from './customSelect';
import { FooterLinks } from './footerLinks';
import { useTranslation } from 'react-i18next';
import styles from './styles/footer.module.css';

type FooterMobileProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  availableValues: any;
};

const FooterMobile = ({ value, availableValues, onChange }: FooterMobileProps) => {
  const { t } = useTranslation('footer');

  return (
    <div className="flex flex-col px-4 lg:hidden" style={{ backgroundColor: '#F7F7F7' }}>
      <CustomDropDown availableLinks={FooterLinks.aboutVisa} title={'Acerca de Visa'} />
      <CustomDropDown availableLinks={FooterLinks.ourValues} title={'Nuestros valores'} />
      <CustomDropDown
        availableLinks={FooterLinks.news}
        title={'Noticias + medios de comunicación'}
      />
      <CustomDropDown availableLinks={FooterLinks.help} title={'Ayuda'} />
      <CustomDropDown availableLinks={FooterLinks.legalPrivacity} title={'Legal + Privacidad'} />
      <div className="relative mt-[51px] flex h-[62px] w-full">
        <span className="absolute left-4 top-[4px] mb-0 text-xs">
          {t('Selecciona el país/la región')}
        </span>
        <div className="flex w-full flex-col border border-gray-900 bg-white">
          <CustomSelect
            className={`${styles.customSelect} h-full cursor-pointer px-[15px] pb-[5px] pt-[23px] text-lg focus:outline-none`}
            value={value}
            onChange={onChange}
            availableValues={availableValues}
          />
        </div>
      </div>
      <div className="flex w-1/2 items-center justify-between pt-[51px]">
        {Object.values(FooterLinks.socialIcons).map((value) => {
          return (
            <a key={value.link} href={value.link} target="_blank" rel="noopener noreferrer">
              <img src={value.title} alt={`${value.alt}`} width={24} height={24} />
            </a>
          );
        })}
      </div>

      <div className="flex flex-wrap pb-7 pt-[51px] text-[14px]">
        © {t('Copyright 1996 - 2022. Todos los derechos reservados.')}
      </div>
    </div>
  );
};

export default FooterMobile;
