import useMediaQuery from '../../../hooks/useMediaQuery';
import DesktopHeader from './desktop';
import MobileHeader from './mobile';

const Header = () => {
  const isMobile = useMediaQuery('(max-width: 1279px)');
  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};

export default Header;
