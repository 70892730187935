import axios, { AxiosError, AxiosInstance } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Cryptography from '../authHelpers/Cryptography';
import { applicationSettings } from '../config';
import { TokenRequestDTO } from '../models/TokenRequestDTO';
import jwtDecode from 'jwt-decode';

type User = {
  email?: string | undefined;
  name?: string | undefined;
} | null;

type AuthState = {
  authenticated: boolean;
  user: User | undefined;
  loading: boolean;
  token: string | null;
  sessionId: string | null;
  logout: (redirectUrl: string, axiosInstance: AxiosInstance) => void;
  getUserId: () => string | null;
};

const AuthContext = createContext<AuthState>({
  authenticated: false,
  user: null,
  loading: true,
  token: null,
  sessionId: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  getUserId: () => null,
});

const getUserId = (): string | null => {
  const tkn = localStorage.getItem('access_token');
  if (tkn) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedHeader: any = jwtDecode(tkn);
    return decodedHeader.external_client_id;
  }
  return null;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [handleCodeRedirect, setHandleCodeRedirect] = useState<boolean>(false);
  const [authState, setAuthState] = useState({
    user: null,
    authenticated: false,
    loading: true,
    token: '',
    sessionId: '',
  });

  const logout = (redirectUrl: string, axiosInstance: AxiosInstance) => {
    axiosInstance.defaults.baseURL = applicationSettings.backEndUrl;
    axiosInstance
      .post('/logout', null, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('code_verifier');
        localStorage.removeItem('session_id');
        setAuthState({
          user: null,
          authenticated: false,
          loading: true,
          token: '',
          sessionId: '',
        });

        window.location.href = redirectUrl;
      })
      .catch((error: AxiosError) => {
        throw new Error(error.response?.statusText);
      });
  };

  const AuthorizeRedirect = async () => {
    localStorage.setItem('code_verifier', Cryptography.generateCodeVerifier());

    const redirectUri = `${applicationSettings.visaAppPageUrl as string}/`;
    const ssoAuthEndpoint = `${applicationSettings.domain}/connect/authorize`;
    const responseType = 'code';

    const sessionId = Cryptography.generateSessionID();
    localStorage.setItem('session_id', sessionId);

    const codeChallengeMethod = 'S256';

    const codeChallenge = await Cryptography.challenge_from_verifier(
      localStorage.getItem('code_verifier') as string
    );

    const redirectToAuthURL = `${ssoAuthEndpoint}?client_id=${applicationSettings.clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${applicationSettings.scope}&state=${sessionId}&code_challenge_method=${codeChallengeMethod}&code_challenge=${codeChallenge}`;

    window.location.href = redirectToAuthURL;
  };

  const loadUser = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const session = localStorage.getItem('session_id');
      if ((token === null || token === undefined || session === null) && !authState.authenticated) {
        if (window.location.href.includes('code')) {
          setHandleCodeRedirect(true);
        } else {
          localStorage.removeItem('access_token');
          localStorage.removeItem('code_verifier');
          localStorage.removeItem('session_id');
          await AuthorizeRedirect();
        }
      } else {
        // redirect to uri
        setAuthState({
          user: null,
          authenticated: true,
          loading: false,
          token: token as string,
          sessionId: session as string,
        });
      }
    } catch (err) {
      setAuthState({
        user: null,
        authenticated: false,
        loading: false,
        token: '',
        sessionId: '',
      });
      localStorage.removeItem('access_token');
      localStorage.removeItem('session_id');
      localStorage.removeItem('code_verifier');
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    const executeEffect = async () => {
      if (handleCodeRedirect) {
        setHandleCodeRedirect(false);
        const param1 = window.location.search.slice(1).split('&')[0].split('=');
        const param2 = window.location.search.slice(1).split('&')[1].split('=');
        const param3 = window.location.search.slice(1).split('&')[2].split('=');
        const code = param1[1]; // get the code
        const scope = param2[1]; //get de scope
        const state = param3[1]; // get the session_id

        if (code === null || scope === null || state === null) {
          throw new Error();
        }

        const verifier = localStorage.getItem('code_verifier');
        const session_id = localStorage.getItem('session_id');

        if (!verifier || !session_id || session_id !== state) {
          throw new Error();
        }

        axios.defaults.baseURL = applicationSettings.backEndUrl;
        const redir = `${window.location.protocol}//${window.location.host}/`;

        const body = {
          sessionId: session_id,
          redirectUri: redir,
          code: code,
          codeVerifier: verifier,
        } as TokenRequestDTO;

        axios
          .post('/connect/token', body, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            localStorage.setItem('access_token', res.data);

            setAuthState({
              user: null,
              authenticated: true,
              loading: false,
              token: res.data,
              sessionId: session_id,
            });

            setTimeout(() => {
              window.location.href = applicationSettings.visaAppPageUrl as string;
            }, 1000);
          })
          .catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              window.location.href = applicationSettings.visaLoginPage as string;
            } else if (
              error.response?.status === 404 &&
              error.response?.data === 'Member not found'
            ) {
              window.location.href = applicationSettings.visaOnboardingPage as string;
            } else {
              throw new Error();
            }
          });
      }
    };
    executeEffect();
  }, [handleCodeRedirect]);

  return (
    <AuthContext.Provider value={{ ...authState, logout, getUserId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { loading, authenticated, token, sessionId, logout, getUserId } = useContext(AuthContext);

  return { loading, authenticated, token, sessionId, logout, getUserId };
};
