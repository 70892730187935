const CatalogIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 500 500"
      version="1.1"
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      className={className}
    >
      <path
        fill="#fcc015"
        d="M259.03 429.07l-21.6-.26L75.11 258.78l-4.18-10.44V86.04l15.11-15.11h170.04l10.68 4.43 162.31 162.31v21.36L259.03 429.07zM101.14 242.29L248.6 396.77l148.43-148.43-147.2-147.2H101.14v141.15z"
      ></path>
      <g>
        <path
          d="M160.54 219.59h147.7v30.21h-147.7z"
          fill="#1434CB"
          transform="rotate(-82.027 234.404 234.704)"
        ></path>
        <path
          d="M172.8 178.19c-20.66-2.92-39.77 11.46-42.69 32.12-2.92 20.66 11.46 39.77 32.12 42.69 1.77.24 3.56.36 5.35.36 20.86 0 37.77-16.9 37.77-37.76 0-18.84-13.89-34.81-32.55-37.41zm-.65 43.49c-3.33 2.51-8.07 1.85-10.58-1.48s-1.85-8.07 1.48-10.58 8.07-1.85 10.58 1.48a7.518 7.518 0 011.45 5.59 7.567 7.567 0 01-2.93 4.99z"
          fill="#1434CB"
        ></path>
        <path
          d="M340.29 216.83c-12.55-16.65-36.22-19.97-52.87-7.43-16.66 12.55-20 36.23-7.45 52.89a37.736 37.736 0 0024.94 14.68c1.77.24 3.56.36 5.35.36 20.86-.07 37.71-17.03 37.64-37.89-.03-8.16-2.7-16.09-7.61-22.61zm-25.69 28.71c-3.34 2.5-8.07 1.83-10.57-1.51s-1.83-8.07 1.51-10.57 8.07-1.83 10.57 1.51c2.54 3.31 1.91 8.05-1.4 10.59-.02.02-.04.03-.06.05l-.05-.07z"
          fill="#1434CB"
        ></path>
      </g>
    </svg>
  );
};

export default CatalogIcon;
