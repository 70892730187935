const HomeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      className={className}
    >
      <path
        fill="#1434cb"
        d="M428.6 423.159H71.4v-29.77h327.43v-162.54L250 114.619l-148.83 116.23v132.77H71.4v-147.34L250 76.839l178.6 139.44v206.88z"
      ></path>
      <path
        fill="#fcc015"
        d="M302.09 363.629H197.91v-133.95h104.18v133.95zm-74.41-29.77h44.65v-74.42h-44.65v74.42z"
      ></path>
    </svg>
  );
};

export default HomeIcon;
