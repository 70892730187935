import React from 'react';

const roundedDiv = ({ className }: { className?: string }) => {
  return (
    <svg width="100%" viewBox="0 0 500 80" preserveAspectRatio="none" className={className}>
      <defs>
        <clipPath id="shape">
          <path d="M0,0 L0,60 Q250,95 500,60 L500,0 Z" />
        </clipPath>
      </defs>
      <rect
        x="0"
        y="0"
        width="500"
        height="80"
        fill="var(--brand-color-primary-pure)"
        clipPath="url(#shape)"
      />
    </svg>
  );
};

export default roundedDiv;
