import LinkedinIconSvg from '../../../icons/linkedin.svg';
import FacebookIconSvg from '../../../icons/facebook.svg';
import InstagramIconSvg from '../../../icons/instagram.svg';
import TwitterIconSvg from '../../../icons/twitter.svg';

export const FooterLinks = {
  aboutVisa: {
    leading: {
      title: 'leading',
      link: 'https://www.visa.com.ar/our-purpose/visa-lidera.html',
    },
    whatWeDo: {
      title: 'whatWeDo',
      link: 'https://www.visa.com.ar/about-visa.html',
    },
    opportunities: {
      title: 'opportunities',
      link: 'https://www.visa.com.ar/careers.html',
    },
  },
  ourValues: {
    social: {
      title: 'social',
      link: 'https://www.visa.com.ar/about-visa/social-impact.html',
    },
    inclusion: {
      title: 'inclusion',
      link: 'https://www.visa.com.ar/visa-everywhere/global-impact/workplace-diversity.html',
    },
  },
  news: {
    pressRoom: {
      title: 'pressRoom',
      link: 'https://www.visa.com.ar/about-visa/newsroom.html',
    },
    newsGlobal: {
      title: 'newsGlobal',
      link: 'https://www.visa.com.ar/visa-everywhere/global-impact.html',
    },
    newsInovation: {
      title: 'newsInovation',
      link: 'https://www.visa.com.ar/visa-everywhere/innovation.html',
    },
    newsSecurity: {
      title: 'newsSecurity',
      link: 'https://www.visa.com.ar/visa-everywhere/security.html',
    },

    relationsInvestors: {
      title: 'relationsInvestors',
      link: 'https://investor.visa.com/',
    },
  },
  help: {
    centerHelp: {
      title: 'centerHelp',
      link: 'https://www.visa.com.ar/contact-us.html',
    },
    rules: {
      title: 'rules',
      link: 'https://www.visa.com.ar/support/consumer/visa-rules.html',
    },
    comunication: {
      title: 'comunication',
      link: 'https://visahome.prismamediosdepago.com/VisaContactForm/contact_form.jsp',
    },
  },
  legalPrivacity: {
    legal: {
      title: 'legal',
      link: 'https://www.visa.com.ar/legal.html',
    },
    privacity: {
      title: 'privacity',
      link: 'https://www.visa.com.ar/legal/privacy-policy.html',
    },
    consumerDefense: {
      title: 'consumerDefense',
      link: 'https://www.visa.com.ar/supporting-info/defensa-consumidor.html',
    },
    protectionData: {
      title: 'protectionData',
      link: 'https://www.visa.com.ar/supporting-info/proteccion-datos-personales.html',
    },
    prevention: {
      title: 'prevention',
      link: 'https://www.visa.com.ar/visa-everywhere/security/prevencion.html',
    },
  },
  socialIcons: {
    facebook: {
      title: FacebookIconSvg,
      link: 'https://www.facebook.com/VisaEspanol/',
      alt: 'facebook-icon',
    },
    twitter: {
      title: TwitterIconSvg,
      link: 'https://twitter.com/VisaArgentina',
      alt: 'twitter-icon',
    },
    linkedin: {
      title: LinkedinIconSvg,
      link: 'https://www.linkedin.com/company/visa',
      alt: 'linkedin-icon',
    },
    instagram: {
      title: InstagramIconSvg,
      link: 'https://www.instagram.com/visa_us/',
      alt: 'instagram-icon',
    },
  },
};
