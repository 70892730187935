/* eslint-disable @typescript-eslint/no-explicit-any */
import { FooterLinks } from './footerLinks';
import CustomSelect from './customSelect';
import { useTranslation } from 'react-i18next';
import styles from './styles/footer.module.css';

type FooterDesktopProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  availableValues: any;
};

const FooterDesktop = ({ value, onChange, availableValues }: FooterDesktopProps) => {
  const { t } = useTranslation('footer');
  return (
    <div
      className="hidden h-auto w-full md:justify-center lg:flex"
      style={{ backgroundColor: '#F7F7F7' }}
    >
      <div className="flex flex-col items-center px-2">
        <div className="flex h-full w-full max-w-6xl flex-col items-start">
          <div className="grid w-full grid-cols-5 gap-x-6 pt-[51px] xl:gap-x-12">
            <div>
              <div className="pb-[25px] text-xl font-bold tracking-[-.4px] text-black">
                {t('Acerca de Visa')}
              </div>
              <div className="grid grid-flow-col gap-x-1 text-[15px] font-light tracking-[0.15px]">
                <ul className="grid gap-y-[21px]">
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.aboutVisa.leading.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.aboutVisa.leading.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.aboutVisa.whatWeDo.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.aboutVisa.whatWeDo.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.aboutVisa.opportunities.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.aboutVisa.opportunities.title)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="pb-[25px] text-xl font-bold tracking-[-.4px] text-black">
                {t('Nuestros valores')}
              </div>
              <div className="grid grid-flow-col gap-x-1  gap-y-4 text-[15px]  font-light tracking-[0.15px]">
                <ul className="grid gap-y-[21px]">
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.ourValues.social.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.ourValues.social.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.ourValues.inclusion.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.ourValues.inclusion.title)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="pb-[25px] text-xl font-bold tracking-[-.4px] text-black">
                {t('Noticias + medios de comunicación')}
              </div>
              <div className="grid grid-flow-col gap-x-1 gap-y-4 text-[15px] font-light tracking-[0.15px]">
                <ul className="grid gap-y-[21px]">
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.news.pressRoom.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.news.pressRoom.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.news.newsGlobal.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.news.newsGlobal.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.news.newsInovation.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.news.newsInovation.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.news.newsSecurity.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.news.newsSecurity.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.news.relationsInvestors.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.news.relationsInvestors.title)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="pb-[25px] text-xl font-bold tracking-[-.4px] text-black">
                {t('Ayuda')}
              </div>
              <div className="grid grid-flow-col gap-x-1 gap-y-4 text-[15px] font-light tracking-[0.15px]">
                <ul className="grid gap-y-[21px]">
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.help.centerHelp.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.help.centerHelp.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.help.rules.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.help.rules.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.help.comunication.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.help.comunication.title)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="pb-[25px] text-xl font-bold tracking-[-.4px] text-black">
                {t('Legal + Privacidad')}
              </div>
              <div className="grid grid-flow-col gap-x-1 gap-y-4 text-[15px] font-light tracking-[0.15px]">
                <ul className="grid gap-y-[21px]">
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.legalPrivacity.legal.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.legalPrivacity.legal.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.legalPrivacity.privacity.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.legalPrivacity.privacity.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.legalPrivacity.consumerDefense.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.legalPrivacity.consumerDefense.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.legalPrivacity.protectionData.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.legalPrivacity.protectionData.title)}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerExternalLink}
                      href={FooterLinks.legalPrivacity.prevention.link}
                      rel="noopener noreferrer"
                    >
                      {t(FooterLinks.legalPrivacity.prevention.title)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <footer className="my-10 w-full">
            <div className="grid grid-cols-5 text-[15px]">
              <div className="col-span-2 flex flex-wrap items-center justify-start">
                <div className="relative h-[62px] w-[350px]">
                  <span className="absolute left-4 top-[4px] mb-0 text-xs">
                    {t('Selecciona el país/la región')}
                  </span>
                  <div className="flex h-full w-full flex-col border border-gray-900 bg-white">
                    <CustomSelect
                      className={`${styles.customSelect} h-full cursor-pointer px-[15px] pb-[5px] pt-[23px] text-[17px] focus:outline-none`}
                      value={value}
                      onChange={onChange}
                      availableValues={availableValues}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-around">
                {Object.values(FooterLinks.socialIcons).map((value) => {
                  return (
                    <a key={value.link} href={value.link} target="_blank" rel="noopener noreferrer">
                      <img src={value.title} alt={`${value.alt}`} width={24} height={24} />
                    </a>
                  );
                })}
              </div>

              <div className="col-span-2 flex flex-wrap items-center justify-end text-[15px] font-light">
                © {t('Copyright 1996 - 2022. Todos los derechos reservados.')}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default FooterDesktop;
