import React from 'react';
import ReactDOM from 'react-dom/client';
import AppWrapper from './wrappers/AppWrapper';
import './index.css';
import './i18n';
import { AuthProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppWrapper />
    </AuthProvider>
  </React.StrictMode>
);
