import { create } from 'zustand';
import { TVoucher } from '../components/home/types';
export type TVoucherStoreState = {
  voucher: TVoucher;
  setCurrentVoucher: (voucher: TVoucher) => void;
  reset: () => void;
};

const defaultState: TVoucher = {
  id: '',
  userId: '',
  benefitId: '',
  benefit: {
    id: '',
    image: '',
    thumbnail: '',
    print: '',
    points: 0,
    discount: {
      value: '',
      type: '',
    },
    company: '',
    locations: [],
    title: '',
    description: '',
    termsAndConditions: '',
    category: '',
    featured: false,
    featuredTitle: '',
    featuredSubtitle: '',
  },
  validDate: '',
  code: '',
  qrCode: '',
  redeemed: false,
};

const useVoucherStore = create<TVoucherStoreState>((set) => ({
  voucher: defaultState,
  setCurrentVoucher: (newVoucher: TVoucher) =>
    set(() => ({
      voucher: { ...newVoucher },
    })),
  reset: () => {
    set(() => ({ voucher: defaultState }));
  },
}));

export default useVoucherStore;
