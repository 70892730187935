import { useTranslation } from 'react-i18next';
import usePopupStore, { TPopupStoreState } from '../../store/popup';

const SevenDaysBtn = () => {
  const { t } = useTranslation('my-points');

  const { showPopup, removePopup } = usePopupStore((state: TPopupStoreState) => state);
  const openPointsExplanationsPopup = () =>
    showPopup({
      title: t('popup.title'),
      description: t('popup.description'),
      confirmText: t('popup.close-text'),
      onConfirm: removePopup,
    });
  return (
    <span
      className="mt-1 flex cursor-pointer align-bottom text-xs font-semibold text-brand-primary md:mt-0 md:text-lg"
      onClick={openPointsExplanationsPopup}
    >
      {t('en los próximos 7 dias')}
    </span>
  );
};

export default SevenDaysBtn;
