import useLoaderStore, { TLoaderStoreState } from '../../store/loader';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const Loader = () => {
  const { t, ready: i18nReady } = useTranslation('global');
  const { loader: state } = useLoaderStore((state: TLoaderStoreState) => state);
  const { show } = state;

  if (!show) return null;

  return (
    <>
      <div className="fixed top-0 z-50  h-full w-full bg-[#CECECE] opacity-80" />
      <div className="shadow-[0px 3px 20px #0000001A] fixed top-[40%] z-[51] w-full px-[30px]">
        <section className="flex flex-col items-center desktop:px-[72px]">
          <div className="mb-2 flex flex-col items-center justify-center ">
            <FontAwesomeIcon
              icon={faArrowsRotate}
              className="animate-spin text-[80px] text-[#1434cb] lg:text-[7vw]"
            />
          </div>
          <span className="text-[20px] font-bold text-[#1434cb] lg:text-[1.5vw]">
            {i18nReady ? `${t('loading')}...` : ''}
          </span>
        </section>
      </div>
    </>
  );
};

export default Loader;
