const PointsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 500 500"
      version="1.1"
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      className={className}
    >
      <defs>
        <path id="SVGID_1_" d="M66.15 66.15h367.7v367.7H66.15z"></path>
      </defs>
      <clipPath id="SVGID_00000163073936637753627580000008741856835572162973_">
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g clipPath="url(#SVGID_00000163073936637753627580000008741856835572162973_)">
        <path
          fill="#ffc015"
          d="M250 95.74c85.2 0 154.26 69.07 154.26 154.26S335.2 404.26 250 404.26 95.74 335.2 95.74 250c.1-85.16 69.1-154.16 154.26-154.26m0-29.59C148.46 66.15 66.15 148.46 66.15 250S148.46 433.85 250 433.85 433.85 351.54 433.85 250 351.54 66.15 250 66.15"
        ></path>
        <path
          fill="#1434cb"
          d="M250 169.87c44.26 0 80.13 35.88 80.13 80.13s-35.88 80.13-80.13 80.13-80.13-35.88-80.13-80.13c.05-44.23 35.9-80.08 80.13-80.13m0-29.59c-60.6-.01-109.73 49.11-109.74 109.7s49.11 109.73 109.7 109.74c60.6.01 109.73-49.11 109.74-109.7V250c0-60.59-49.11-109.71-109.7-109.72"
        ></path>
      </g>
    </svg>
  );
};

export default PointsIcon;
