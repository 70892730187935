import { Link, useLocation } from 'react-router-dom';
import VisaIcon from '../../../icons/visa';
import useHeaderLinks from './useHeaderLinks';
import { useTranslation } from 'react-i18next';
import { applicationSettings } from '../../../config';

const DesktopHeader = () => {
  const links = useHeaderLinks();
  const { pathname } = useLocation();
  const { t } = useTranslation('routes');
  const isMyPoints = pathname === t('my-points');

  const desktopLinks = () => {
    const setLinkColor = (linkPath: string | undefined) => {
      if (linkPath === '/logout' && !isMyPoints) return 'text-black';
      if (linkPath === pathname)
        return `${isMyPoints ? 'text-white' : 'text-brand-primary'} font-medium`;
      return `${
        isMyPoints ? 'text-white' : 'text-neutral-gray-ultradark'
      } flex items-center gap-[3.5px]`;
    };

    return links.map(({ label, path, Icon, isExternal }) => {
      const props = { href: path, to: path || '/', className: setLinkColor(path) };
      const children = [
        label,
        path === 'https://visa.com.ar' && (
          <Icon key={`${label}-icon`} className="h-[16px] w-[16px]" />
        ),
      ];

      const externalLink = <a {...props}>{children}</a>;
      const internalLink = <Link {...props}>{children}</Link>;

      return <li key={label}>{isExternal ? externalLink : internalLink}</li>;
    });
  };

  return (
    <header
      className={`flex h-[98px] items-center border-b border-[#dedede] px-10 text-[min(16px,_1.1vw)] ${
        isMyPoints ? 'bg-brand-primary' : 'bg-white'
      }`}
    >
      <a href={applicationSettings.visaAppPageUrl} className="flex flex-grow items-center">
        <VisaIcon className="h-[30px] w-[92px]" color={isMyPoints ? '#FFFFFF' : '#1434CB'} />
      </a>

      <nav className="flex">
        <ul className="flex justify-end gap-8">{desktopLinks()}</ul>
      </nav>
    </header>
  );
};

export default DesktopHeader;
