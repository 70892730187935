import { useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import useMediaQuery from '../../hooks/useMediaQuery';
import Footer from '../layout/footer/index';
import Header from './header';
import PointsMobile from '../pointsMobile';
import BannerPoints from '../pointsDesktop/bannerPoints';
import Popup from '../shared/popup';
import Loader from '../shared/loader';
import useUserStore, { TUserStoreState } from '../../store/user';
import useBenefitStore, { TBenefitStoreState } from '../../store/benefit';
import useVoucherStore, { TVoucherStoreState } from '../../store/voucher';
import useErrorStore, { TErrorStoreState } from '../../store/error';
import styles from './styles.module.css';
import MobileWhatsAppBanner from './whatsapp-banner/mobile';
import DesktopWhatsAppBanner from './whatsapp-banner/desktop';

const Layout = () => {
  const { t } = useTranslation('routes');
  const [isOpenPoints, setOpenPoints] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1279px)');
  const { pathname } = useLocation();

  const error = useErrorStore((state: TErrorStoreState) => state.error);
  const benefitSelected = useBenefitStore((state: TBenefitStoreState) => state.benefit);
  const user = useUserStore((state: TUserStoreState) => state.user);
  const currentVoucher = useVoucherStore((state: TVoucherStoreState) => state.voucher);
  const bannerAllowedRoutes = [t('/benefits-catalog'), t('/benefit/*', { benefitId: '*' })];

  const bannerPointsTexts = [
    {
      pathname: t('/benefits-catalog'),
      firstTextBackground: 'Catálogo de',
      secondTextBackground: 'beneficios',
    },
    {
      pathname: t('/benefit/*', { benefitId: '*' }),
      firstTextBackground: undefined,
      secondTextBackground: currentVoucher.id ? 'Felicitaciones' : benefitSelected?.company,
    },
  ];
  const checkRoute = (allowedRoute: string) => {
    const regExpression = new RegExp(allowedRoute, 'i');
    return regExpression.test(pathname);
  };

  const openCondition = !checkRoute(t('my-points')) && !currentVoucher.id && !error.show;
  return (
    <>
      <div className="relative mx-auto w-full max-w-[1920px]">
        <div className={`${isOpenPoints && isMobile ? styles.overlay : 'bg-transparent'}`} />
        <div className="h-full">
          <div className="pd:mb-0 sticky top-0 z-30 bg-[#f7f7f7]">
            <Header />
            {isMobile && openCondition && (
              <Suspense>
                <PointsMobile
                  setOpen={setOpenPoints}
                  open={isOpenPoints}
                  myPoints={user.points}
                  pointsToBeat={user.pointsToBeat}
                  benefitRequiredPoints={benefitSelected.points}
                />
              </Suspense>
            )}
            {!isOpenPoints && openCondition && isMobile && <MobileWhatsAppBanner />}
          </div>
          {!error.show &&
            !isMobile &&
            bannerAllowedRoutes.some((allowedRoute) => checkRoute(allowedRoute)) && (
              <BannerPoints
                myPoints={user.points}
                firstTextBackground={
                  bannerPointsTexts.find((route) => checkRoute(route.pathname))
                    ?.firstTextBackground || ''
                }
                secondTextBackground={
                  bannerPointsTexts.find((route) => checkRoute(route.pathname))
                    ?.secondTextBackground || ''
                }
              />
            )}
          <Outlet />
        </div>
        {!isOpenPoints && <Footer />}
        {!isMobile && <DesktopWhatsAppBanner />}
        <Toaster />
      </div>
      <Popup />
      <Loader />
    </>
  );
};

export default Layout;
