/* eslint-disable @typescript-eslint/no-explicit-any */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/footer.module.css';

type CustomDropDownProps = {
  title: string;
  availableLinks: any;
};

const CustomDropDown = ({ title, availableLinks }: CustomDropDownProps) => {
  const { t } = useTranslation('footer');
  const [show, setShow] = useState(false);

  return (
    <div className={`${show ? 'pt-[30px]' : 'pt-[51px]'}`}>
      <div className="flex w-full flex-col">
        <div className={`flex flex-row items-center justify-between ${show ? 'my-4' : 'my-0'}`}>
          <div className="text-lg">{t(title)}</div>
          <div className="flex">
            {show ? (
              <FontAwesomeIcon
                onClick={() => setShow(!show)}
                icon={faChevronDown}
                className="translate-y-0.5 rotate-180 text-sm font-bold text-brand-primary text-[#777777]"
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setShow(!show)}
                icon={faChevronDown}
                className="text-sm font-bold text-[#777777]"
              />
            )}
          </div>
        </div>
        {show && (
          <div className="grid grid-flow-col gap-x-1 text-[15px]">
            <ul className="grid gap-y-3">
              {Object.values(availableLinks).map((item: any) => (
                <li key={item.link}>
                  <a
                    href={item.link}
                    rel="noopener noreferrer"
                    className={styles.footerExternalLink}
                  >
                    {t(item.title)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropDown;
